import React, { useState } from "react"
import { Box, Flex, Heading, Container, Text } from "@chakra-ui/react"
import { Provider, FilterStack, SensorStack, useSearch } from "@usereactify/search"

import config from "@root/config.default.js"

import { useAppContext } from "@app/providers/app"

import { SearchSort } from "@app/components/Search/Sort/SearchSort"
import { SearchResults } from "@app/components/Search/Results/SearchResults"
import { Filters } from "@app/components/Search/Filters/Filters"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { useContent } from "@app/hooks/useContent"

import type { Props } from "@app/pages/search"
import type { PageProps } from "@root/types/global"

const Search: React.FC<PageProps<Props>> = ({ page }) => {
  const { activeStore } = useAppContext()
  const content = useContent(page)
  const [isFilter, setFiltersVisible] = useState(false)
  const toggleFilter = () => setFiltersVisible(!isFilter)
  return (
    <Provider shopifyPermanentDomain={`${activeStore.shopName}.myshopify.com`} index={config.services.reactify.index ?? ""}>
      <SensorStack />
      <Container as="section" maxW="container.xxl" px={[4, 16]} pb={[4, 12]}>
        <Box as="section" py={[4, 8]}>
          <Flex justifyContent="space-between">
            <Heading as="h1" size="h1" mb={4}>
              {page?.title} - &quot;
              <SearchQuery />
              &quot;
            </Heading>
          </Flex>
          {content}
        </Box>
        <Flex borderBottom="2px solid" borderColor="grey.200">
          <Flex w="100%" alignItems="center" position="relative">
            <Flex alignItems="center" cursor="pointer" onClick={toggleFilter}>
              <Text size="10" fontWeight="600">
                FILTER
              </Text>
              <Text size="10" variant="gray" mx="2">
                (0)
              </Text>
              {isFilter ? <BsChevronUp size="12" /> : <BsChevronDown size="12" />}
            </Flex>
          </Flex>
          <Box w="100%" d="flex" justifyContent="flex-end">
            <Text mr="2" size="10" pt="6px">
              SORT BY:
            </Text>
            <SearchSort />
          </Box>
        </Flex>
        <Box display={isFilter ? "block" : "none"} transition="all 0.3s">
          <FilterStack render={filters => <Filters filters={filters} />} />
        </Box>
        <Flex mt={[4, 8]}>
          <Box w="100%">
            <SearchResults />
          </Box>
        </Flex>
      </Container>
    </Provider>
  )
}

export default Search

const SearchQuery: React.FC = () => {
  const { searchQuery } = useSearch()
  if (!searchQuery) return null
  return <span>{searchQuery}</span>
}
